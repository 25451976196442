$( document ).ready( function() {
	var currentSlide = 0;
	function showSlide( slideIndex ) {
		$( ".lifestyle__item" ).removeClass( "active" );
		$( ".lifestyle__item--img" ).removeClass( "active" );
		
		$( ".lifestyle__item:eq(" + slideIndex + ")" ).addClass( "active" );
		$( ".lifestyle__item--img:eq(" + slideIndex + ")" ).addClass( "active" );
		
		$( ".lifestyle__nav--item" ).removeClass( "active" );
		$( ".lifestyle__item--img" ).removeClass( "active" );
		
		$( ".lifestyle__nav--item:eq(" + slideIndex + ")" ).addClass( "active" );
		$( ".lifestyle__item--img:eq(" + slideIndex + ")" ).addClass( "active" );
	}
	
	showSlide( currentSlide );
	
	$( ".lifestyle__nav--item" ).click( function() {
		currentSlide = $( this ).data( "target" );
		showSlide( currentSlide );
	} );
	
	$( ".lifestyle__slider--prev" ).click( function() {
		currentSlide = ( currentSlide - 1 + $( ".lifestyle__item" ).length ) % $( ".lifestyle__item" ).length;
		showSlide( currentSlide );
	} );
	
	$( ".lifestyle__slider--next" ).click( function() {
		currentSlide = ( currentSlide + 1 ) % $( ".lifestyle__item" ).length;
		showSlide( currentSlide );
	} );
} );

